import React, { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components";
import PageLayout from "../../components/page-layout"
import SEO from "../../components/seo"

// -- contstants

const baoMasterFarmerContract = "0xf712a82DD8e2Ac923299193e9d6dAEda2d5a32fd"

const coinGeckoIds = {
  'BAO': 'bao-finance',
  'XDAI': 1,
  'WETH': 'weth',
  'RUNE': 'thorchain',
  'GRT': 'the-graph',
  '1INCH': '1inch',
  'LINK': 'chainlink',
  'USDC': 1,
  'COMP': 'compound-governance-token',
  'USDT': 1,
  'SNX': 'havven',
  'WBTC': 'wrapped-bitcoin',
  'AAVE': 'aave',
  'CRV': 'curve-dao-token',
  'BAO.cx': 'bao-finance',
  'CEL': 'celsius-degree-token',
  'AGVE': 'agave-token',
  'NFTX': 'nftx'
}

const initialClickedPairs = {
  'BAO-XDAI': true,
  'BAO-BAO.cx': true,
  'BAO-WETH': true
}

const pools = {
  'BAO-XDAI': {
    contractAddress: '0x82820a99c431d0Bb7cA775Fa7247d1AC481f2E56'
  },
  'BAO-BAO.cx': {
    contractAddress: '0x42d69d4b7fC4506504dc4aaA224565B6618e5722'
  },
  'BAO-WETH': {
    contractAddress: '0x4659640F3444e96ac96cb901177486c1775aAE09'
  },
  'WBTC-XDAI': {
    contractAddress: '0xa498fFe098f4dc9a52FAB6fBdd5c624Ca237F39c'
  },
  'AAVE-XDAI': {
    contractAddress: '0xfE146525b01dcF721d0714eb46a2E5dE3C01357a'
  },
  'RUNE-XDAI': {
    contractAddress: '0x0378cc2fb49Ae06b857dEBfcbB45339dC692802C'
  },
  'GRT-XDAI': {
    contractAddress: '0xF8f02044B74F34CBd83dCa483547B7F32768Fe50'
  },
  '1INCH-XDAI': {
    contractAddress: '0x8746355882E10AAE144d3709889dfAA39FF2a692'
  },
  'LINK-XDAI': {
    contractAddress: '0x4cCB2Fe7472c0a6f73a7154023a6F652F24694ee'
  },
  'USDC-XDAI': {
    contractAddress: '0x71c20bfCb1170E1643ccDf1FF25714615eEF6701'
  },
  'COMP-XDAI': {
    contractAddress: '0x2fB0dD74f6365Ff77dA7Aa7D4b1B790847a5DA00'
  },
  'SNX-XDAI': {
    contractAddress: '0xCEAd5C71231764aBfc8B809824666603E8614853'
  },
  'CRV-XDAI': {
    contractAddress: '0xCdf15b16B5dd71f17ef1d1996292Af205f960A68'
  },
  'GRT-WBTC': {
    contractAddress: '0x40B8EB2575926B1F67C939B01d1716296576dc33'
  },
  'NFTX-XDAI': {
    contractAddress: '0x9fbB63681bD9939514Fc437944B404E8e5208E20'
  },
  'CEL-XDAI': {
    contractAddress: '0xDb9f7C72B9bCE159dba62f3E4C84477A6Baf4597'
  },
  'AGVE-XDAI': {
    contractAddress: '0xcf7f4a04f204fcFea7d8617C9c8e80f95920A8c5'
  },
  'LINK-WBTC': {
    contractAddress: '0xD61F580370E8C53757935119B7c08818f238506d'
  },
  'SNX-WBTC': {
    contractAddress: '0xbbB23f86cCb36c471bF466c36Ae6C38De417EF16'
  },
  'AAVE-WBTC': {
    contractAddress: '0x25BFfC0B93536Ac36FEe29B028Cde0BDfA74Ff60'
  }
}

// -- styled components

const FarmInfoRow = styled.p``

const FarmInfoRowBold = styled.span`
  font-weight: bold;
  padding-right: 0.3rem;
`

const LpTokenSelectionCard = styled.div`
  padding: 0.75rem 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: ${props => props.isSelected ? "#ddd" : "white"};

  border: ${props => props.isSelected
    ? "1px solid cornflowerblue" : "1px solid #ccc"};

  &:hover {
    cursor: pointer;
  }
`

const LpTokenSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  padding-bottom: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width:600px) and (max-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const LpTokenSelectionInfo = styled.p`
  text-align: center;
`

const LpTokenValueCard = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
`

const LpTokenValueCardContainer = styled.div``

const LpTokenValueCardTitle = styled.div`
  font-weight: bold;
`

// -- helper functions 

const collectCoinPrices = coinPricesData => {
  const res = {}
  Object.keys(coinGeckoIds).forEach(symbol => {
    const coinGeckoId = coinGeckoIds[symbol]
    if (coinGeckoId === 1) {
      res[symbol] = 1
      return
    }
    res[symbol] = coinPricesData[coinGeckoId].usd
  })
  return res
}

const fetchAllCoinPrices = () => {
  const idsList = []
  Object.keys(coinGeckoIds).forEach(symbol => {
    idsList.push(coinGeckoIds[symbol])
  })
  const idsListNoDups = [...new Set(idsList)]
  const ids = idsListNoDups.join(',')
  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`
  return axios.get(url)
}

const fetchLpStakedInFarm = (lpTokenAddress) => {
  const apiUrl = "https://blockscout.com/poa/xdai/api?module=account&action=tokenbalance&"
    + `contractaddress=${lpTokenAddress}`
    + `&address=${baoMasterFarmerContract}`
  return axios.get(apiUrl)
}

const fetchTokenBalances = tokenAddress => {
  const apiUrl = "https://blockscout.com/poa/xdai/api?module=account&" 
    + `action=tokenlist&address=${tokenAddress}`
  return axios.get(apiUrl)
}

const fetchTotalTokenSupply = tokenAddress => {
  const apiUrl = "https://blockscout.com/poa/xdai/api?module=token&"
    + `action=getToken&contractaddress=${tokenAddress}`
  return axios.get(apiUrl)
}

const formatNumber = (num, decimalPlaces) => {
  return numberWithCommas(num.toFixed(decimalPlaces))
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// -- helper components

const LpTokenSelection = ({lpTokenPair, isSelected, onClick}) => {
  return (
    <LpTokenSelectionCard
      onClick={() => onClick(lpTokenPair)}
      isSelected={isSelected}
    >
      {lpTokenPair}
    </LpTokenSelectionCard>
  )
}

const LpTokenValue = ({pairName, coinPrices}) => {
  const [farmInfo, setFarmInfo] = useState({})
  const assetA = pairName.split('-')[0]
  const assetB = pairName.split('-')[1]
  const contractAddress = pools[pairName]['contractAddress']

  useEffect(() => {
    fetchTotalTokenSupply(contractAddress).then(totalSupplyRes => {
      fetchLpStakedInFarm(contractAddress).then(lpStakedRes => {
        fetchTokenBalances(contractAddress).then(balancesRes => {
          const balances = {}
          const lpTotalSupply = totalSupplyRes.data.result.totalSupply / 10 ** 18
          const lpTokensStaked = lpStakedRes.data.result / 10 ** 18

          const blockscoutLookupSymbols = pools[pairName]["blockscoutLookupSymbols"] 
            || [assetA, assetB]

          blockscoutLookupSymbols[0] = blockscoutLookupSymbols[0] === "XDAI" 
            ? "WXDAI" : blockscoutLookupSymbols[0]

            blockscoutLookupSymbols[1] = blockscoutLookupSymbols[1] === "XDAI" 
            ? "WXDAI" : blockscoutLookupSymbols[1]

          blockscoutLookupSymbols.forEach((symbol, i) => {
            balancesRes.data.result.forEach(balanceObj => {
              const decimals = Number(balanceObj.decimals)
              if (balanceObj.symbol === symbol) {
                balances[pairName.split('-')[i]] = balanceObj.balance / 10 ** decimals
              }
            })
          })
    
          setFarmInfo({
            lpTokensStaked,
            lpTotalSupply,
            balances
          })
        })
      })
    })
  }, [pairName, coinPrices, contractAddress, assetA, assetB])

  const lpTotalSupply = farmInfo["lpTotalSupply"] ? farmInfo["lpTotalSupply"] : 0
  const lpTokensStaked = farmInfo["lpTokensStaked"] ? farmInfo["lpTokensStaked"] : 0
  const balances = farmInfo["balances"] ? farmInfo["balances"] : {}

  const pctStaked = lpTokensStaked / lpTotalSupply
  const balanceAStaked = balances[assetA] * pctStaked
  const balanceBStaked = balances[assetB] * pctStaked

  const valueAStaked = balanceAStaked * coinPrices[assetA]
  const valueBStaked = balanceBStaked * coinPrices[assetB]
  const totalValueLocked = valueAStaked + valueBStaked

  return (
    <LpTokenValueCard>
      <LpTokenValueCardTitle>
        {pairName}
      </LpTokenValueCardTitle>
      <FarmInfoRow>
        <FarmInfoRowBold>Total Value Locked:</FarmInfoRowBold>
        ${formatNumber(totalValueLocked, 2)}
      </FarmInfoRow>
      <FarmInfoRow>
        <FarmInfoRowBold>Percent Staked:</FarmInfoRowBold>
        {formatNumber(pctStaked * 100, 2)}%
      </FarmInfoRow>
      <FarmInfoRow>
        <FarmInfoRowBold>
        Tokens Staked:
        </FarmInfoRowBold>
        {
          `${formatNumber(balanceAStaked, 2)} ${assetA}
          + ${formatNumber(balanceBStaked, 2)} ${assetB}`
        }
      </FarmInfoRow>
      <FarmInfoRow>
        <a 
          target="_blank"
          rel="noopener noreferrer"
          href={`https://blockscout.com/poa/xdai/address/${contractAddress}`}
        >
          Blockscout LP Token Page
        </a>
      </FarmInfoRow>
    </LpTokenValueCard>
  )
}

// -- main function

export default function BaoFinanceXDaiFarmsTVL() {
  const [clickedPairs, setClickedPairs] = useState(initialClickedPairs)
  const [coinPrices, setCoinPrices] = useState({})

  const noneSelected = Object.keys(clickedPairs).reduce((acc, cur) => {
    return clickedPairs[cur] ? acc.concat(cur) : acc
  }, []).length === 0

  useEffect(() => {
    fetchAllCoinPrices().then(res => {
      setCoinPrices(collectCoinPrices(res.data))
    })
  }, [])

  const onSelectionClick = lpTokenPair => {
    if (lpTokenPair in clickedPairs) {
      clickedPairs[lpTokenPair] = !clickedPairs[lpTokenPair]
      setClickedPairs(JSON.parse(JSON.stringify(clickedPairs)))
    } else {
      clickedPairs[lpTokenPair] = true;
      setClickedPairs(JSON.parse(JSON.stringify(clickedPairs)))
    }
  }

  return (
    <PageLayout showAffiliateLink>
      <SEO
        title="BAO Finance xDai Farms TVL"
        description="Find TVL of bao finance farms on xDai"
      />

      <h3>Select Farms (only baoswap farms for now)</h3>
      <LpTokenSelectionContainer>
        {
          Object.keys(pools).map((poolName, i) => {
            return (
              <LpTokenSelection
                key={i}
                lpTokenPair={poolName}
                onClick={onSelectionClick}
                isSelected={clickedPairs[poolName]}
              />
            )
          })
        }
      </LpTokenSelectionContainer>
      <LpTokenSelectionInfo>
        more being added soon :)
      </LpTokenSelectionInfo>
      <hr />

      <h3>Farm TVLs</h3>
      <LpTokenValueCardContainer>
        {
          Object.keys(clickedPairs).map((pairName, i) => {
            if (!clickedPairs[pairName]) {
              return null;
            }
            return (
              <LpTokenValue
                key={i}
                coinPrices={coinPrices}
                pairName={pairName}
              />
            )
          })
        }
        { 
          noneSelected &&
          <p>Please select at least one farm.</p>
        }
      </LpTokenValueCardContainer>
    </PageLayout>
  )
}